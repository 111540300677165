import React from "react"

import { graphql } from "gatsby"
import Seo from "@components/Seo"
import Helmet from "react-helmet"
import { ServiceHero } from "@components/sections/heros/ServiceHero"
import DownloadAssetBanner from "@components/sections/DownloadAssetBanner"
import { DownloadAssetPopup } from "@components/sections/DownloadAssetPopup"
import { ServiceContentSections } from "@components/sections/service/ServiceContentSections"
import { ContactFormSection } from "@components/sections/ContactFormSection"

export default function ServicePage({ data }) {
  const page = data.allWpService.nodes[0]

  const { title } = page

  const {
    mainTitle,
    subtitle,
    heroImage,
    heroContent,
    contentSections
  } = page.acfServiceFields

  const { databaseId, acfPageExtras } = page
  const { serviceIcon } = page.serviceDetails

  const parent = page.wpParent?.node
  const heroTitle = mainTitle ? mainTitle : title

  return (
    <>
      <Seo {...page.seo} />
      <Helmet
        bodyAttributes={{
          class: "short"
        }}
      />

      <ServiceHero
        title={heroTitle}
        subtitle={subtitle}
        content={heroContent}
        image={heroImage?.image}
        link={heroImage?.link}
        parent={
          parent && {
            title: parent.title,
            url: parent.url
          }
        }
        serviceIcon={heroImage?.icon || serviceIcon}
      />

      {acfPageExtras?.addDownloadReportBanner && (
        <DownloadAssetBanner
          style={{
            marginTop: "30px"
          }}
        />
      )}

      {acfPageExtras?.addDownloadReportPopover && <DownloadAssetPopup />}

      {contentSections && (
        <ServiceContentSections
          pageTitle={mainTitle || title}
          databaseId={databaseId}
          childServices={page.wpParent?.node?.wpChildren?.nodes}
          contentSections={contentSections}
        />
      )}

      <ContactFormSection />
      <br />
      <br />
    </>
  )
}

export const query = graphql`fragment WpPortfolioDetails on WpPortfolio {
    title
    acfPortfolio {
        clientUrl
        featuredImage {
            altText
            localFile {
                extension
                childImageSharp {
                    gatsbyImageData(width: 230, placeholder: BLURRED, layout: CONSTRAINED)
                }
                publicURL
            }
        }
    }
    pageFeaturedImage: featuredImage {
        node {
            localFile {
                extension
                childImageSharp {
                    gatsbyImageData(width: 230,placeholder: BLURRED, layout: CONSTRAINED)
                }
                publicURL
            }
        }
    }
}

fragment serviceIconDetails on WpService_Servicedetails {
    icon: serviceIcon {
        localFile {
            extension
            publicURL
            childImageSharp {
                gatsbyImageData(width: 50, height: 50, placeholder: BLURRED, layout: FIXED)
            }
        }
    }
}

fragment WpServiceDetails on WpService {
    title
    url: uri
    serviceDetails {
        ...serviceIconDetails
    }
}

fragment WpServiceDetailsParent on WpService {
    wpChildren {
        nodes {
            ... on WpService {
                title
                url: uri
                databaseId
                wpParent {
                    node {
                        ... on WpService {
                            serviceDetails {
                                ...serviceIconDetails
                            }
                        }
                    }
                }
                serviceDetails {
                    shortDescription: moduleExcerpt
                    readMoreText: moduleReadMore
                    ...serviceIconDetails
                }
            }
        }
    }
}

query ($slug: String!) {
    allWpService(filter: {slug: {eq: $slug}}) {
        nodes {
            id
            uri
            title
            content
            link
            databaseId
            seo {
                canonical
                cornerstone
                focuskw
                metaDesc
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphAuthor
                opengraphDescription
                opengraphModifiedTime
                opengraphPublishedTime
                opengraphPublisher
                opengraphSiteName
                opengraphTitle
                opengraphImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, layout: FIXED)
                        }
                    }
                }
                opengraphType
                opengraphUrl
                title
                twitterDescription
                twitterTitle
                schema {
                    raw
                }
            }
            acfPageExtras {
                addDownloadReportBanner
                selectBanner
                addDownloadReportPopover
                selectPopover
            }
            serviceDetails {
                fieldGroupName
                serviceIcon {
                    localFile {
                        extension
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 50, height: 50, layout: FIXED)
                        }
                    }
                }
                moduleExcerpt
                moduleReadMore
                niceTitle
            }
            wpParent {
                node {
                    ... on WpService {
                        ...WpServiceDetails
                        ...WpServiceDetailsParent
                    }
                }
            }
            acfServiceFields {
                contentSections {
                    ... on WpService_Acfservicefields_ContentSections_FreeText {
                        content
                        addEnquiryForm
                        formPosition
                        fieldGroupName
                    }
                    ... on WpService_Acfservicefields_ContentSections_FreeTextWithSidebar {
                        content
                        fieldGroupName
                        sidebarType
                        sidebarTitle
                        sidebarTextFields {
                            fieldGroupName
                            link
                            text
                            icon {
                                localFile {
                                    extension
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 50
                                            height: 50
                                            placeholder: BLURRED
                                            transformOptions: {fit: INSIDE, cropFocus: CENTER}
                                            layout: FIXED
                                        )
                                    }
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_MultiSidebar {
                        fieldGroupName
                        multiSidebar {
                            sidebarTitle
                            sidebarTextFields {
                                link
                                text
                                icon {
                                    localFile {
                                        extension
                                        publicURL
                                        childImageSharp {
                                            gatsbyImageData(width: 50, height: 50, placeholder: BLURRED, layout: FIXED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_Solutions {
                        fieldGroupName
                        sectionTitle
                        ourSolutions {
                            title
                            content
                            icon {
                                localFile {
                                    extension
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(width: 100, placeholder: BLURRED, layout: CONSTRAINED)
                                    }
                                }
                            }
                            pageLink {
                                ... on WpService {
                                    uri
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_OtherServices {
                        fieldGroupName
                    }
                    ... on WpService_Acfservicefields_ContentSections_LatestArticles {
                        fieldGroupName
                        title
                        blogArticlesCategory {
                            posts {
                                nodes {
                                    title
                                    id
                                    uri
                                    featuredImage {
                                        node {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(
                                                        width: 584
                                                        height: 400
                                                        transformOptions: {fit: COVER, cropFocus: CENTER}
                                                        layout: CONSTRAINED
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_AwardsWeWon {
                        fieldGroupName
                    }
                    ... on WpService_Acfservicefields_ContentSections_Faq {
                        fieldGroupName
                        faq {
                            answer
                            question
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_SubServices {
                        feedType
                        fieldGroupName
                        parentService {
                            ... on WpService {
                                serviceDetails {
                                    ...serviceIconDetails
                                }
                                wpChildren {
                                    nodes {
                                        ... on WpService {
                                            ...WpServiceDetails
                                        }
                                    }
                                }
                            }
                        }
                        subServices {
                            icon {
                                localFile {
                                    extension
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData(width: 50, height: 50, placeholder: BLURRED, layout: FIXED)
                                    }
                                }
                            }
                            readMoreText
                            shortDescription
                            title
                            url
                        }
                        title
                    }
                    ... on WpService_Acfservicefields_ContentSections_QuestionSlider {
                        fieldGroupName
                        title
                        questions {
                            answer
                            question
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_CaseStudies {
                        fieldGroupName
                        title
                        casestudies {
                            caseStudy {
                                ... on WpCaseStudy {
                                    title
                                    uri
                                    featuredImage {
                                        node {
                                            localFile {
                                                extension
                                                publicURL
                                                childImageSharp {
                                                    gatsbyImageData(
                                                        width: 609
                                                        height: 609
                                                        transformOptions: {cropFocus: CENTER}
                                                        layout: CONSTRAINED
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    acfCaseStudyFeatured {
                                        blockContent
                                        coverImage {
                                            localFile {
                                                extension
                                                publicURL
                                                childImageSharp {
                                                    gatsbyImageData(layout: FULL_WIDTH)
                                                }
                                            }
                                        }
                                        featuredImage {
                                            localFile {
                                                extension
                                                publicURL
                                                childImageSharp {
                                                    gatsbyImageData(height: 70, layout: FIXED)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_ContentImage {
                        fieldGroupName
                        content
                        isIcon
                        acfImage {
                            localFile {
                                extension
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(width: 600, layout: CONSTRAINED)
                                }
                            }
                        }
                        link {
                            title
                            url
                        }
                        imagePlacement
                    }
                    ... on WpService_Acfservicefields_ContentSections_WeWorkWith {
                        fieldGroupName
                        feedType
                        portfolioPieces {
                            ... on WpPortfolio {
                                ...WpPortfolioDetails
                            }
                        }
                        service {
                            portfolios {
                                nodes {
                                    ...WpPortfolioDetails
                                }
                            }
                        }
                    }
                    ... on WpService_Acfservicefields_ContentSections_WebTimeline {
                        fieldGroupName
                    }
                    ... on WpService_Acfservicefields_ContentSections_Testimonials {
                        fieldGroupName
                        title
                        testimonialsRelationship {
                            testimonials {
                                nodes {
                                    title
                                    acfTestimonials {
                                        testimonial
                                        clientName
                                        author
                                        clientLogo {
                                            localFile {
                                                extension
                                                publicURL
                                                childImageSharp {
                                                    gatsbyImageData(
                                                        width: 609
                                                        transformOptions: { fit: CONTAIN}
                                                        layout: CONSTRAINED
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                heroContent
                subtitle
                mainTitle
                heroImage {
                    icon {
                        localFile {
                            extension
                            publicURL
                            childImageSharp {
                                gatsbyImageData(width: 50, height: 50, layout: FIXED)
                            }
                        }
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 550
                                    height: 423
                                    placeholder: BLURRED
                                    quality: 95
                                    layout: CONSTRAINED
                                    transformOptions: {cropFocus: CENTER}
                                )
                            }
                        }
                    }
                    
                    link {
                        url
                        title
                        target
                    }
                }
            }
        }
    }
}
`
